import React from "react";
import styled from "styled-components";
import BgBoxTop from "./svg/bg_box_top";
import BgBoxBottom from "./svg/bg_box_bottom";

import styles from "./styles.json";

const AboutSection = props => {
  return (
    <StyledPeople id="about">
      <BgBoxTop />

      <WhiteBox>
        <Head>{props.aboutContent.subtitle}</Head>

        <div>
          {props.aboutContent.text.map(block => (
            <Section key={block}>{block}</Section>
          ))}
        </div>
      </WhiteBox>

      <BgBoxBottom />
    </StyledPeople>
  );
};

export default AboutSection;

const StyledPeople = styled.div`
  z-index: 2;
  position: relative;
  padding-top: 4vh;
  padding-bottom: 4vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${styles.text.black};
`;

const WhiteBox = styled.div`
  max-width: 700px;
  padding: 30px;
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const Head = styled.div`
  padding-bottom: 20px;
  font-weight: 700;
`;
