import React from "react";
import styled from "styled-components";

function BgBoxTop() {
  return (
    <Wrapper>
      <svg
        viewBox="0 0 1440 86"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop-HD-Copy"
            transform="translate(0.000000, -474.000000)"
            fill="#f5f5f5"
            fillRule="nonzero"
          >
            <polygon id="Path-2" points="1440 560 0 560 0 474" />
          </g>
        </g>
      </svg>
    </Wrapper>
  );
}

export default BgBoxTop;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  overflow-x: hidden;
  transform: translateY(-98%);
  opacity: 1;
  svg {
    display: block;
    width: 105vw;
  }
`;
