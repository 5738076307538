import React, { Component } from "react";
import Nav from "./Nav";
import styled from "styled-components";
import Plx from "react-plx";

import AboutSection from "./About";
import BusinessSection from "./business";

import TorenLogo from "./img/toren_logo.png";
import MMDlogo from "./img/MMD_logo.png";
import AlpadisLogo from "./img/alpadis_logo.png";

import styles from "./styles.json";
import content from "./content.json";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: "swe",
      langContent: content.swe
    };
  }

  handleLangChange = () => {
    if (this.state.langContent === content.swe) {
      this.setState({
        lang: "eng",
        langContent: content.eng
      });
    } else {
      this.setState({
        lang: "swe",
        langContent: content.swe
      });
    }
  };

  render() {
    return (
      <OuterWrapper>
        <Nav handleLangChange={this.handleLangChange} content={this.state.langContent.menu} />
        <Header className="header" id="header">
          <StyledPlx parallaxData={plsDataLogo}>
            <Logo>Engström & Rinde</Logo>
            <Sublogo>{this.state.langContent.sections.head.subtitle}</Sublogo>
          </StyledPlx>
        </Header>

        <AboutSection aboutContent={this.state.langContent.sections.about} lang={this.state.lang} />

        <Colabs id="colabs">
          <TextHeadingColabs>{this.state.langContent.sections.colabs.title}</TextHeadingColabs>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{ paddingBottom: "25px", cursor: "pointer" }}
              onClick={() => window.open("http://www.toren.ch/", "_blank")}
            >
              <img src={TorenLogo} alt="Toren logo" />
            </div>
            <div
              style={{ paddingBottom: "25px", cursor: "pointer" }}
              onClick={() => window.open("https://www.mmdillon.com", "_blank")}
            >
              <img src={MMDlogo} alt="mmd logo" />
            </div>
            <div
              style={{ paddingBottom: "25px", cursor: "pointer" }}
              onClick={() => window.open("https://www.alpadis-group.com", "_blank")}
            >
              <img src={AlpadisLogo} alt="Alpadis logo" />
            </div>
          </div>
        </Colabs>

        <BusinessSection
          title={this.state.langContent.sections.business.title}
          items={this.state.langContent.sections.business.items}
          lang={this.state.lang}
          titlePeople={this.state.langContent.sections.people.title}
        />

        <Contact id="contact">
          <TextHeading>{this.state.langContent.sections.contact.title}</TextHeading>
          <ContactBox>
            <ContDiv>
              <Bold>{this.state.langContent.sections.contact.text}</Bold>
            </ContDiv>
            <ContDiv style={{ cursor: "pointer" }} onClick={() => (window.location = "mailto:info@engstrom-rinde.se")}>
              info(at)engstrom-rinde.se
            </ContDiv>
          </ContactBox>
          <ContactBox>
            <ContDiv>
              <Bold>Mats Engström</Bold>, Stockholm, Sverige
            </ContDiv>
            <ContDiv style={{ cursor: "pointer" }} onClick={() => (window.location = "mailto:mats@engstroem.se")}>
              mats (at) engstroem.se
            </ContDiv>
            <ContDiv>tel: +46 708 25 24 40</ContDiv>
            <ContDiv>skype: m_engstroem</ContDiv>
          </ContactBox>
          <ContactBox>
            <ContDiv>
              <Bold>Sigurd Rinde</Bold>, Zurich, Schweiz
            </ContDiv>
            <ContDiv style={{ cursor: "pointer" }} onClick={() => (window.location = "mailto:sig@rinde.com")}>
              sig (at) rinde.com
            </ContDiv>
            <ContDiv>tel: +41 79 932 71 05</ContDiv>
            <ContDiv>skype: sigurd.rinde</ContDiv>
          </ContactBox>
        </Contact>
      </OuterWrapper>
    );
  }
}

export default App;

const plsDataLogo = [
  {
    start: ".header",
    duration: "100%",
    properties: [
      {
        startValue: 0,
        endValue: 1100,
        unit: "px",
        property: "translateY"
      }
    ]
  }
];

const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4em;
  /* a {
    &:hover {
      opacity: 0.5;
    }
  } */
`;

const Header = styled.div`
  z-index: 0;
  flex-basis: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: blue;
  background-image: ${styles.gradient.down};
  line-height: initial;
`;
const StyledPlx = styled(Plx)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.div`
  z-index: 1;
  color: ${styles.text.white};
  font-size: calc(20px + 3vw);
`;
const Sublogo = styled.div`
  z-index: 1;
  color: ${styles.text.white};
  font-size: calc(10px + 1vw);
`;

const TextHeading = styled.div`
  font-size: calc(20px + 1.5vw);
  padding-bottom: 30px;
`;
const TextHeadingColabs = styled.div`
  font-size: calc(20px + 1.5vw);
  padding-bottom: 50px;
`;

const Colabs = styled.div`
  padding: 14vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: blue;
  background-image: ${styles.gradient.up};
  color: ${styles.text.white};
`;

const Contact = styled.div`
  padding: 14vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: blue;
  background-image: ${styles.gradient.down};
  color: ${styles.text.white};
`;

const ContactBox = styled.div`
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const ContDiv = styled.span`
  padding-bottom: 10px;
`;
