import React from "react";
import styled from "styled-components";
import BgBoxTop from "./svg/bg_box_top";
import BgBoxBottom from "./svg/bg_box_bottom";

import styles from "./styles.json";

const BusinessSection = props => {
  return (
    <StyledBusiness>
      <BgBoxTop />

      <TextHeading id="people">{props.titlePeople}</TextHeading>

      {props.lang === "swe" ? (
        <>
          <WhiteBox>
            <Bold>Sigurd Rinde</Bold> är född 1952, examinerades 1977 som Diplomierte Chemieingenieur från ETH i Zürich,
            Schweiz, och 1979 som MBA från INSEAD i Fontainebleau, Frankrike. <br />
            <br />
            Efter examen har Sigurd verkat som investor i flera Leveraged Buy-Outs och Start-ups.
            <br />
            <br />
            Sigurd driver alltjämt en av dessa start-ups, tex. Thingamy, en mjukvaruutvecklare av rang! Läs mer:
            www.thingamy.com
            <br />
            <br />
            Sigurd Rinde är gift med Tittin, tillsammans har de tre söner.
          </WhiteBox>
          <WhiteBox>
            <Bold>Mats Engström</Bold> är född 1954, examinerades som civilekonom från Handelshögskolan i Stockholm 1977
            efter specialisering på redovisning/finansiering samt kostnads-/intäktsanalys.
            <br />
            <br />
            Efter examen var Mats på SE-Banken där han under åtta år arbetade på flera avdelningar, bl.a. Investment
            Banking.
            <br />
            <br />
            Därefter har Mats fleråriga erfarenheter från fondkommissionärer, finansbolag, kapitalförvaltningsbolag
            utöver ett flertal styrelseuppdrag i mellanstora och mindre bolag.
            <br />
            <br />
            Mats Engström är gift med Gina, tillsammans har de en son och en dotter.
          </WhiteBox>
        </>
      ) : (
        <>
          <WhiteBox>
            <Bold>Sigurd Rinde</Bold> is born in April 1952, graduated 1977 as Diplomierte Chemieingenieur from ETH in
            Zürich, Switzerland, and 1979 as MBA from INSEAD in Fontainebleau, France. <br />
            <br />
            Mr. Rinde has since his MBA graduation been an investor doing Leveraged Buy-Outs and start-ups.
            <br />
            <br />
            Sigurd is still active in one of these start-ups, i.e. Thingamy, a software developer of rank! Please refer
            to www.thingamy.com
            <br />
            <br />
            Mr. Rinde is married to Tittin, they have three sons.
          </WhiteBox>
          <WhiteBox>
            <Bold>Mats Engström</Bold> is born in April 1954, graduated 1977 as Civilekonom DHS from Stockholm School of
            Economics, Sweden. <br />
            <br />
            After the education Mr. Engström joined SE-Banken where he had during eight years positions both in
            commercial banking and in the area of investment banking.
            <br />
            <br />
            Mr. Engström joined thereafter a stockbroker, as a specialist in the corporate finance area and subsequently
            an independent finance company as a specialist in the area of Mergers and Acquisitions, M&A.
            <br />
            <br />
            Mr. Engström is married to Gina, they have one son and one daughter.
          </WhiteBox>
        </>
      )}

      <TextHeading style={{ marginTop: "60px" }} id="business">
        {props.title}
      </TextHeading>
      <WhiteBox>
        {props.items.map(item => (
          <Item key={item.name}>
            <div className="left">{item.name}</div>
            <div className="right">{item.text}</div>
          </Item>
        ))}
      </WhiteBox>

      <BgBoxBottom />
    </StyledBusiness>
  );
};

export default BusinessSection;

const StyledBusiness = styled.div`
  z-index: 2;
  position: relative;

  padding: 4vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${styles.text.black};
`;
const TextHeading = styled.div`
  font-size: calc(20px + 1.5vw);
  padding-bottom: 30px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding-bottom: 15px;

  .left {
    padding-right: 30px;
    font-weight: 700;
  }
  .right {
    flex: 1;
  }
`;

const WhiteBox = styled.div`
  max-width: 700px;
  background-color: white;
  padding: 30px;
  margin: 15px;
  border-radius: 10px;
`;
const Bold = styled.span`
  font-weight: 700;
`;
