import React, { Component } from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MediaQuery from "react-responsive";

import styles from "./styles.json";

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scroll: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    this.setState({
      scroll: window.pageYOffset
    });
  };

  render() {
    return (
      <NavWrapper className={this.state.scroll > 30 ? "loose" : "stuck"}>
        <NavResponsCont>
          <NavBox>
            <MediaQuery minWidth={800}>
              <StyledLink href="#about" offset="100">
                {this.props.content.about}
              </StyledLink>
              <StyledLink href="#colabs" offset="100">
                {this.props.content.colabs}
              </StyledLink>
              <StyledLink href="#people" offset="100">
                {this.props.content.people}
              </StyledLink>
              <StyledLink href="#business" offset="100">
                {this.props.content.business}
              </StyledLink>
              <StyledLink href="#contact" offset="100">
                {this.props.content.contact}
              </StyledLink>
            </MediaQuery>
            <MediaQuery maxWidth={800}>
              <StyledLinkLogo href="#header" className={this.state.scroll > 400 ? "show" : "hide"}>
                Engström & Rinde
              </StyledLinkLogo>
            </MediaQuery>
          </NavBox>
          <NavBox>
            <LangItem onClick={this.props.handleLangChange}>{this.props.content.lang}</LangItem>
          </NavBox>
        </NavResponsCont>
      </NavWrapper>
    );
  }
}

export default Nav;

const NavWrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms;
  &.stuck {
    background-color: none;
    color: ${styles.text.white};
  }
  &.loose {
    background-color: ${styles.bg.white};
    color: ${styles.text.black};
    height: 40px;
    box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.5);
  }
`;

const NavResponsCont = styled.div`
  flex: 1;
  max-width: 900px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavBox = styled.div`
  display: flex;
`;

const StyledLink = styled(AnchorLink)`
  text-decoration: none;
  color: inherit;
  padding: 15px;
  margin: 0px 5px;
  transition: opacity 400ms;
  &:hover {
    text-decoration: underline;
  }
  &.hide {
    opacity: 0;
  }
`;
const StyledLinkLogo = styled(AnchorLink)`
  text-decoration: none;
  color: inherit;
  padding: 15px 0;
  margin: 0px 0px;
  transition: opacity 400ms;
  &:hover {
    text-decoration: underline;
  }
  &.hide {
    opacity: 0;
  }
`;

const LangItem = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
