import React from "react";
import styled from "styled-components";

function BgBoxBottom() {
  return (
    <Wrapper>
      <svg
        viewBox="0 0 1440 86"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop-HD-Copy"
            transform="translate(0.000000, -1554.000000)"
            fill="#f5f5f5" // f5f5f5
            fillRule="nonzero"
          >
            <polygon id="Path-2-Copy" points="1440 1640 0 1554 1441 1554" />
          </g>
        </g>
      </svg>
    </Wrapper>
  );
}

export default BgBoxBottom;

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  transform: translateY(98%);
  opacity: 1;
  svg {
    position: relative;
    display: block;
    width: 105vw;
  }
`;
